@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@300;400&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Playfair Display', serif;
}

.home-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.background.active {
  opacity: 1;
}

.section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section.active {
  opacity: 1;
  z-index: 1;
}

.content-container {
  position: absolute;
  top: 50px; /* Height of the sticky header */
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Style scrollbar for webkit browsers */
.content-container::-webkit-scrollbar {
  width: 8px;
}

.content-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.content-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px; /* Adjust this value if needed */
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0; /* Remove vertical padding */
  display: flex; /* Add this to ensure content is vertically centered */
  align-items: center; /* Add this to ensure content is vertically centered */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Keep horizontal padding */
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  height: 100%; /* Ensure the content takes full height of the header */
}

.balaji-logo {
  width: 25px; /* Slightly reduced size */
  margin-bottom: 2px;
  height: auto;
  filter: brightness(0) saturate(100%) invert(80%) sepia(42%) saturate(681%) hue-rotate(358deg) brightness(101%) contrast(103%);
}

.ganesh-logo {
  width: 35px; /* Slightly reduced size */
  margin-bottom: 2px;
  height: auto;
  filter: brightness(0) saturate(100%) invert(80%) sepia(42%) saturate(681%) hue-rotate(358deg) brightness(101%) contrast(103%);
}

.couple-names-container {
  display: flex;
  align-items: center;
}

.couple-names {
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
}

.section-outline {
  flex-grow: 1;
  margin: 0 20px;
}

.section-outline ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-outline li {
  padding: 4px 8px; /* Reduced padding */
  cursor: pointer;
  color: #fff;
  transition: all 0.5s ease;
  border-radius: 20px;
  margin: 0 5px;
}

.section-outline li:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.section-outline li.active {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.3);
}

/* Adjust the top padding of the first section to account for the fixed header */
.section:first-child {
  padding-top: 50px; /* Should match the header height */
}

.mobile-menu-toggle {
  color: #fff;
  display: none;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }
}

.swipe-indicator {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 50%;
  z-index: 1000;
  animation: fadeInOut 2s ease-in-out infinite;
  cursor: pointer;
}

.swipe-indicator.left {
  left: 10px;
}

.swipe-indicator.right {
  right: 10px;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.2; }
  50% { opacity: 1; }
}

@media (min-width: 769px) {
  .swipe-indicator {
    display: none;
  }
}
