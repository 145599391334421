.section-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align to top on mobile */
  overflow-y: auto; /* Enable scrolling on the section-section for mobile */
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

.section-container {
  width: 60%;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 2rem;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 700px;
}

.section-container h2 {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1rem;
}

.section-container h3 {
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.section-container p, .section-container ul {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 0.7rem;
}

.section-container ul {
  padding-left: 1.2rem;
}

.section-container a {
  color: #0066cc;
  text-decoration: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .section-container h2 {
    display: none;
  }

  .section-section {
    align-items: flex-start; /* Align to top on mobile */
    overflow-y: auto; /* Enable scrolling on the section-section for mobile */
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
  }

  .section-container {
    width: 95%;
    padding: 1.5rem;
    margin-top: 20px;
    margin-bottom: 120px;
    max-width: 100%;
  }

  .section-section::-webkit-scrollbar {
    width: 6px;
  }

  .section-section::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  .section-section::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  .section-section::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}
