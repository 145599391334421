.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-menu nav ul {
  list-style-type: none;
  padding: 0;
}

.mobile-menu nav ul li {
  margin: 20px 0;
  font-size: 24px;
  color: white;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
