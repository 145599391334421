.gallery-override {
  width: 80%;
  max-width: 1200px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 200px;
    gap: 10px;
    margin-top: 20px;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .item-1 {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .item-4 {
    grid-column: span 2;
  }
  
  .gallery-item:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .gallery-override {
      width: 95%;
      padding: 1rem;
      max-width: 1200px;
    }

    .gallery-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .item-1, .item-4 {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
  
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
  }
  
  .lightbox img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    cursor: default;
  }
  
  .lightbox-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 16px;
    font-size: 24px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .lightbox-nav:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .lightbox-nav.prev {
    left: 20px;
  }
  
  .lightbox-nav.next {
    right: 20px;
  }

  @media (max-width: 768px) {
    .lightbox-nav {
      font-size: 16px;
      padding: 8px;
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f0f0f0;
    color: #333;
  }