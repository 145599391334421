.hotel-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hotel-card {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: auto;
  min-height: 150px; 
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.hotel-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.hotel-card-image {
  width: 30%;
  min-width: 100px;
  height: auto;
  object-fit: cover;
}

.hotel-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  font-size: 0.8rem;
  color: #333;
}

.hotel-card-content h3 {
  margin: 0 0 0.25rem 0;
  font-size: 1.1rem;
  color: #333;
}

.hotel-address {
  font-size: 0.8rem;
  color: #666;
  margin: 0 0 0.5rem 0;
}

.hotel-details {
  font-size: 0.75rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.hotel-links {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}

.hotel-links a {
  padding: 4px 8px;
  font-size: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s ease-in-out;
}

.hotel-links a:hover {
  background-color: #e0e0e0;
}

.hotel-card.fully-booked {
  position: relative;
  opacity: 1.0;
}

.fully-booked-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4d;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  z-index: 1;
}

.hotel-links a.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.hotel-links a.disabled:hover {
  background-color: #ccc;
}

@media (max-width: 768px) {
  .travel-container {
    width: 95%;
  }

  .hotel-card {
    flex-direction: column;
    height: auto;
  }

  .hotel-card-image {
    width: 100%;
    height: 180px;
  }
}
