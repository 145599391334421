@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.hero-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  text-align: center;
}

.hero-names {
  font-family: 'Great Vibes', cursive;
  font-size: 4rem;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.hero-date {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.hero-location {
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
}

.hero-location p {
  margin: 3px 0;
}

.hero-section .rsvp-button {
  background-color: rgba(153, 128, 5, 0.6);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 50px;
  width: 250px;
}

.hero-section .rsvp-button:hover {
  background-color: rgba(0, 0, 0, 1);
}

@media (max-width: 768px) {
  .hero-names {
    font-size: 3.5rem;
    font-weight: 500;
    margin: 0 0 8px 0;
  }

  .hero-section {
    align-items: flex-start;
    margin-top: 50%;
  }
  
  .hero-content {
    text-align: center;
  }

  .hero-section .rsvp-button {
    margin-top: 30px;
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

